<style lang="scss" scoped>
.page-system-areasmanage {}
/deep/ .page-system-areasmanage-dialog {
    max-width: 520px;
    .c-datetime-range {
        max-width: 100% !important;
    }
}
</style>

<template>
    <div class="page-system-areasmanage">
        <div class="toolbars mrgb5">
            <div class="buttons mrgb5">
                <div class="fl">
                    <el-button-group class="mrgr5 mrgb5">
                        <el-button type="primary" size="medium" v-for="(item, index) in spiders" :key="index" @click="execSpider(index)">{{ item }}</el-button>
                    </el-button-group>
                </div>
                <div class="clearb"></div>
            </div>
        </div>
        <div>
            <el-table :data="tableData" border size="small">
                <el-table-column label="服务区ID" prop="id"></el-table-column>
                <el-table-column label="名称" prop="name"></el-table-column>
                <el-table-column label="状态">
                    <template slot-scope="scope">
                        <el-tag type="primary" size="small" v-if="scope.row.isActive">启用</el-tag>
                        <el-tag type="info" size="small" v-else>禁用</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="更新时间" prop="updatedAt"></el-table-column>
                <el-table-column label="操作" width="205px" align="right">
                    <template slot-scope="scope">
                        <el-dropdown trigger="click" v-if="scope.row.isActive">
                            <el-button class="mrgr5" type="warning" plain size="mini">运行爬虫<i class="el-icon-arrow-down el-icon--right"></i></el-button>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item v-for="(item, index) in spiders" :key="item" v-show="index > 0"
                                    @click.native="execSpider(index, scope.row)">{{ item }}</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                        <el-button type="info" plain size="mini" v-if="scope.row.isActive" @click.native="setStatus(scope.row)">设为禁用</el-button>
                        <el-button type="primary" plain size="mini" v-else @click.native="setStatus(scope.row)">设为启用</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <!--爬虫-->
        <spider :ref="refSpider"></spider>
    </div>
</template>

<script>
import * as funAll from "@/api/all"
import * as funSpider from "@/api/spider"
import moment from "moment"
import spider from "./components/spider"
export default {
    name: "pageSystemAreasManage",
    components: { spider },
    data() {
        return {
            refSpider: "refAreasManageToSpider",
            listQuery: {
                isActive: null
            },
            tableData: [],
            curClickSpider: 0,
            allAreas: [],
            showDialog: false,
            dialogData: {
                areas: [],
                beginDate: "",
                endDate: ""
            },
            formRefName: "refAreasManageDialog",
            spiders: ["重爬所有服务区", "重爬站点", "重爬订单", "重爬挪车任务", "重爬钱包", "重爬用户", "重爬车辆日志"],
            selectedTime: []
        }
    },
    mounted() {
        this.getDataList()
    },
    methods: {
        getDataList() {
            window.$common.fullLoading()
            this.listQuery.area = this.$store.getters.serviceArea || ""
            funAll.AllServiceAreas(this.listQuery).then(response => {
                window.$common.closeFullLoading()
                this.tableData = response
                this.allAreas = response.filter(x => x.isActive)
            })
        },
        execSpider(index, row) {
            let name = this.spiders[index]
            this.curClickSpider = index
            if (row && row.name) {
                this.dialogData.areas = [row.name]
            }
            this.confirm(`确定要${name}吗？`).then(()=>{
                if (name === this.spiders[0]) {
                    // 所有服务区
                    funSpider.execServiceAreaSpider().then(() => {
                        this.commonSuccess()
                    })
                } else {
                    this.openParams()
                }
            })
        },
        openParams() {
            // this.showDialog = true
            let params = {
                areas: [],
                index: this.curClickSpider,
            }
            this.$refs[this.refSpider].open(params)
        },
        setStatus(row) {
            let txt = row.isActive ? "禁用" : "启用"
            this.confirm(`确定要${txt}“${row.name}”服务区吗？`).then(()=>{
                window.$common.fullLoading()
                funAll.UpdateAreasActive({ id: row.id, isActive: !row.isActive }).then(() => {
                    location.reload()
                })
            })
        },
        commonSuccess() {
            this.successMsg("爬虫已运行，请稍后查看数据是否正确!")
        }
    }
}
</script>