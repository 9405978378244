<style lang="scss" scoped>
/deep/ .comp-user-spider {
    max-width: 520px;
    .c-datetime-range {
        max-width: 100% !important;
    }
}
</style>

<template>
    <div>
        <el-dialog title="选择服务区和时间" custom-class="c-el-dialog comp-user-spider" :visible.sync="showDialog"
            :before-close="_close" :close-on-click-modal="false">
            <el-form :model="dialogData" label-width="80px" size="small">
                <el-row :gutter="10">
                    <el-col :xs="24">
                        <el-form-item label="服务区">
                            <el-select class="c-pw100" placeholder="请选择服务区(可多选)" size="medium" multiple
                                clearable v-model="dialogData.areas" :disabled="readonly">
                                <el-option v-for="ws in allAreas" :key="ws.id" :label="ws.name" :value="ws.name"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" v-if="curSpider > 1">
                        <el-form-item label="时间段">
                            <el-date-picker class="c-datetime-range" v-model="selectedTime" type="datetimerange" size="medium"
                                :default-time="['00:00:00', '23:59:59']"
                                range-separator="至"
                                start-placeholder="开始时间"
                                end-placeholder="结束时间"
                                align="right">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer">
                <el-button size="small" @click="_close()">关闭</el-button>
                <el-button size="small" type="primary" @click="_save()">确定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import * as funAll from "@/api/all"
import * as funSpider from "@/api/spider"
import moment from "moment"
export default {
    name: "compUserAreas",
    data() {
        return {
            showDialog: false,
            dialogData: {
                areas: [],
                beginDate: "",
                endDate: ""
            },
            curSpider: -1,
            allAreas: [],
            spiders: ["重爬所有服务区", "重爬站点", "重爬订单", "重爬挪车任务", "重爬钱包", "重爬用户"],
            selectedTime: [],
            readonly: false
        }
    },
    methods: {
        async open(row) {
            await this.getAllAreas()
            this.curSpider = row.index
            this.dialogData.areas = row.areas
            this.readonly = row.readonly || false
            this.showDialog = true
        },
        async getAllAreas(){
            window.$common.fullLoading()
            await funAll.AllServiceAreas({ isActive: true }).then(res => {
                window.$common.closeFullLoading()
                this.allAreas = res
            })
        },
        _save() {
            let submitData = this.tranceCommitData()
            if (!submitData) {
                return false
            }
            console.log(submitData)
            let index = this.curSpider
            if (index === 1) {
                // 站点
                funSpider.execParkingSpider(submitData).then(() => {
                    this.commonSuccess()
                })
            } else if (index === 2) {
                // 订单
                funSpider.execOrderSpider(submitData).then(() => {
                    this.commonSuccess()
                })
            } else if (index === 3) {
                // 挪车任务
                funSpider.execMoveTaskSpider(submitData).then(() => {
                    this.commonSuccess()
                })
            } else if (index === 4) {
                // 钱包
                funSpider.execWalletSpider(submitData).then(() => {
                    this.commonSuccess()
                })
            } else if (index === 5) {
                console.log(submitData)
                // 用户
                funSpider.execUserSpider(submitData).then(() => {
                    this.commonSuccess()
                })
            } else if (index === 6) {
                console.log(submitData)
                // 车辆日志
                funSpider.execVehicleLogSpider(submitData).then(() => {
                    this.commonSuccess()
                })
            }
        },
        tranceCommitData() {
            let commitData = JSON.parse(JSON.stringify(this.dialogData))
            if (this.curSpider < 0) {
                this.warningMsg("请选择爬虫任务!")
                return undefined
            }
            if (!commitData.areas || commitData.areas.length <= 0) {
                this.warningMsg("请选择服务区!")
                return undefined
            }
            if (this.curSpider > 1) {
                if (!this.selectedTime || this.selectedTime.length < 2 || !moment(this.selectedTime[0]).isValid() || !moment(this.selectedTime[1]).isValid()) {
                    this.warningMsg("请选择运行爬虫的时间段!")
                    return undefined
                }
                commitData.beginDate = moment(this.selectedTime[0]).format("YYYY-MM-DD HH:mm:ss")
                commitData.endDate = moment(this.selectedTime[1]).format("YYYY-MM-DD HH:mm:ss")
            } else {
                delete commitData.beginDate
                delete commitData.endDate
            }
            return commitData
        },
        _close() {
            this.showDialog = false
            this.curSpider = -1
            this.dialogData.areas = []
            this.dialogData.selectedTime = []
            this.dialogData.beginDate = ""
            this.dialogData.endDate = ""
        },
        commonSuccess() {
            this.successMsg("爬虫已运行，请稍后查看数据是否正确!")
            this._close()
            this.refreshData(true)
        },
        refreshData(data) {
            this.$emit("refreshData", data)
        }
    }
}
</script>