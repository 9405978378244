import request from '@/utils/request'

export function execServiceAreaSpider(data) {
  return request({
    url: '/api/app/spider/service-area',
    method: 'post',
    data
  })
}

export function execParkingSpider(data) {
  return request({
    url: '/api/app/spider/parking',
    method: 'post',
    data
  })
}

export function execOrderSpider(data) {
  return request({
    url: '/api/app/spider/order',
    method: 'post',
    data
  })
}

export function execMoveTaskSpider(data) {
  return request({
    url: '/api/app/spider/move-task',
    method: 'post',
    data
  })
}

export function execWalletSpider(data) {
  return request({
    url: '/api/app/spider/wallet',
    method: 'post',
    data
  })
}

export function execUserSpider(data) {
  return request({
    url: '/api/app/spider/user',
    method: 'post',
    data
  })
}

export function execVehicleLogSpider(data) {
  return request({
    url: '/api/app/spider/vehicle-log',
    method: 'post',
    data
  })
}
